import React, { useState } from "react"
import { Container, Row, Col, Modal } from "react-bootstrap"
import MainSection from "./main-section"
import Image from "gatsby-image"

import { GrLinkNext, GrLinkPrevious } from "react-icons/gr"

const JkfImageGallery = ({ images }) => {
  const x = images.files.edges

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [imgIndex, setIndex] = useState(0)

  const toggleModalAndSetIndex = index => {
    handleShow()
    setIndex(index)
  }

  return (
    <MainSection>
      <Container>
        <Row>
          {x.map((image, index) => (
            <Col
              xs={6}
              md={3}
              key={index}
              onClick={() => toggleModalAndSetIndex(index)}
            >
              <div>
                <Image fluid={image.node.childImageSharp.fluid} />
                <p
                  style={{
                    backgroundColor: "rgb(51,51,51)",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  {image.node.childImageSharp.fluid.originalName}
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <Modal centered="true" show={show} onHide={handleClose}>
        <Modal.Body style={{ padding: 0, position: "relative" }}>
          <Image fluid={x[imgIndex].node.childImageSharp.fluid} />
          <button
            style={{ cursor: "pointer", border: "none", background: "none" }}
            className="jkfPrev"
            onClick={() =>
              imgIndex === 0 ? setIndex(x.length - 1) : setIndex(imgIndex - 1)
            }
          >
            <GrLinkPrevious fontSize="35" />
          </button>

          <button
            style={{ cursor: "pointer", border: "none", background: "none" }}
            className="jkfNext"
            onClick={() =>
              imgIndex >= x.length - 1 ? setIndex(0) : setIndex(imgIndex + 1)
            }
          >
            <GrLinkNext fontSize="35" />
          </button>
        </Modal.Body>
      </Modal>
    </MainSection>
  )
}

export default JkfImageGallery
